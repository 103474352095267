import React, { useState } from "react";
import ResponsiveSidebar from "./ResponsiveSidebar";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import workotic from "../../assets/workotic-logo.svg";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';
import { has } from 'lodash';
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { TbUsers } from "react-icons/tb";
import { PiUsersFour } from "react-icons/pi";
import { FaPhotoFilm } from "react-icons/fa6";
import { GrDocumentTime } from "react-icons/gr";
import { AiOutlineProject } from "react-icons/ai";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { PiClockUserLight } from "react-icons/pi";
import clsx from "clsx";

export default function SidebarContent() {
  const [open, setOpen] = useState(false);
  const { activeOrganization } = useSelector((state) => state.auth);
  const { plan: { features } } = activeOrganization;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };



  return (
    <>
      <button
        className='lg:hidden bg-slate-200 absolute m-2 p-2 px-3 -mt-12 rounded-md mx-2 hover:bg-slate-300'
        onClick={showDrawer}>
        <MenuUnfoldOutlined />
      </button>
      <ResponsiveSidebar
        close={onClose}
        open={open}
        width='20%'>
        <Link to='/'>
          <img
            src={workotic}
            alt='workotic-logo'
            className=' md:w-44 md:ml-4 lg:mx-4 lg:w-2/3 lg:ml-8 lg:-mt-8 '
          />
        </Link>

        {/* Main Navigation */}
        <div className='md:ml-8 lg:ml-10 xl:ml-14 my-4 lg:my-10 md:my-16 font-medium'>
          <div>
            <p className='text-gray-400 my-3 font-medium text-[13px]'>
              Insights
            </p>
            <NavLink
              to='/'
              className={({ isActive }) => clsx({
                'flex dashboard-link  text-[#455A64] items-center gap-3 my-6 hover:text-primary transition-all duration-100 ease-in-out focus:text-primary active:text-primary': true,
                'text-primary': isActive
              })}>
              <LiaChalkboardTeacherSolid size={25} />
              <p className='text-[15px] '>Dashboard</p>
            </NavLink>
          </div>
          {has(features, 'team-management') && (
            <div>
              <p className='text-gray-400 my-3 font-medium text-[13px]'>
                Team Management
              </p>
              {["admin"].includes(activeOrganization?.role) &&
                <NavLink
                  to='/team'
                  className={({ isActive }) => clsx({
                    'flex dashboard-link  text-[#455A64] items-center gap-3 my-6 hover:text-primary transition-all duration-100 ease-in-out focus:text-primary active:text-primary': true,
                    'text-primary': isActive
                  })}>
                  <TbUsers size={20} />
                  <p className='text-[15px] '>Team</p>
                </NavLink>
              }
              <NavLink
                to='/department'
                className={({ isActive }) => clsx({
                  'flex dashboard-link  text-[#455A64] items-center gap-3 my-6 hover:text-primary transition-all duration-100 ease-in-out focus:text-primary active:text-primary': true,
                  'text-primary': isActive
                })}>
                <PiUsersFour size={22} />
                <p className='text-[15px] '>Departments</p>
              </NavLink>
            </div>
          )

          }
          <div>
            <p className='text-gray-400 my-3 font-medium text-[13px]'>
              Productivity
            </p>
            <NavLink
              to='/screenshots'
              className={({ isActive }) => clsx({
                'flex dashboard-link  text-[#455A64] items-center gap-3 my-6 hover:text-primary transition-all duration-100 ease-in-out focus:text-primary active:text-primary': true,
                'text-primary': isActive
              })}>
              <FaPhotoFilm size={20} />
              <p className='text-[15px] '>Screenshot</p>
            </NavLink>
            {
              has(features, 'timesheet') && (
                <NavLink
                  to='/reports/timesheet/weekly'
                  className={({ isActive }) => clsx({
                    'flex dashboard-link  text-[#455A64] items-center gap-3 my-6 hover:text-primary transition-all duration-100 ease-in-out focus:text-primary active:text-primary': true,
                    'text-primary': isActive
                  })}>
                  <GrDocumentTime size={20} />
                  <p className='text-[15px] '>Timesheets</p>
                </NavLink>
              )
            }
          </div>
          <div>
            <p className='text-gray-400 my-3 font-medium text-[13px]'>
              Project Management
            </p>
            <NavLink
              to='/projects'
              className={({ isActive }) => clsx({
                'flex dashboard-link  text-[#455A64] items-center gap-3 my-6 hover:text-primary transition-all duration-100 ease-in-out focus:text-primary active:text-primary': true,
                'text-primary': isActive
              })}>
              <AiOutlineProject size={22} />
              <p className='text-[15px] '>Projects</p>
            </NavLink>
            <NavLink
              to='/meetings'
              className={({ isActive }) => clsx({
                'flex dashboard-link  text-[#455A64] items-center gap-3 my-6 hover:text-primary transition-all duration-100 ease-in-out focus:text-primary active:text-primary': true,
                'text-primary': isActive
              })}>
              <PiClockUserLight size={25} />
              <p className='text-[15px]'>Meetings</p>
            </NavLink>
          </div>
          <div>
            <p className='text-gray-400 my-3 font-medium text-[13px]'>
              Reports
            </p>
            <NavLink
              to='/reports'
              className={({ isActive }) => clsx({
                'flex dashboard-link  text-[#455A64] items-center gap-3 my-6 hover:text-primary transition-all duration-100 ease-in-out focus:text-primary active:text-primary': true,
                'text-primary': isActive
              })}>
              <HiOutlineDocumentReport size={22} />
              <p className='text-[15px] '>Reports</p>
            </NavLink>
          </div>
          {["admin"].includes(activeOrganization?.role) &&
            <div>
              <p className='text-gray-400 my-3 font-medium text-[13px]'>
                Configurations
              </p>
              <NavLink
                to='/settings'
                className={({ isActive }) => clsx({
                  'flex dashboard-link  text-[#455A64] items-center gap-3 my-6 hover:text-primary transition-all duration-100 ease-in-out focus:text-primary active:text-primary': true,
                  'text-primary': isActive
                })}>
                <HiOutlineCog6Tooth size={22} />
                <p className='text-[15px] '>Settings</p>
              </NavLink>
            </div>
          }
        </div>
      </ResponsiveSidebar>
    </>
  );
}
