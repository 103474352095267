import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import TeamReports from './components/TeamReports'
import ProjectReports from './components/ProjectReports'

export default function Reports() {

  return (
    <MainLayout>
      <div className='md:m-4 rounded-md m-2 xl:p-4'>
        <h1 className='xl:text-2xl text-2xl lg:text-xl my-4 font-semibold  font-poppins '>Reports Overview</h1>
        <div className="space-y-5">
          <TeamReports />
          <ProjectReports />
        </div>
      </div>
    </MainLayout>
  )
}
