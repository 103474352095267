export const tooltip = {
  activeTime: "The Time Tracked While Clocked In",
  loggedTime: "The Time Tracked While Clocked In and Working on the Project",
  currentStatus: "Status of Tracker App",
  totalTeamMembers: "Number of team members who have joined your organization.",
  activeMembers: "Number of members who are active on tracker app.",
  clockedOutMembers: "Number of members who have clocked out on tracker app.",
  yetToStartMembers: "Number of members who haven’t clocked in.",
  hoursPerDay: "The assigned hours of user per day. User can also exceed the hours. If the job timings are 9 to 5. You can set it at 8."
};
