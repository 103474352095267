import {Avatar, Table, Tooltip, Empty , Space} from "antd";
import React, {memo, useCallback, useEffect, useState} from "react";
import DepartmentDeleteModal from "./DepartmentDeleteModal";
import DepartmentModal from "./DepartmentModal";
import Api from "../../utils/Axios";
import DepartmentIcon from "./DepartmentIcon";
import {usePageUtils} from "../../contexts/PageUtilsContext";
import Search from "antd/es/input/Search";

const DepartmentTable = memo(function DepartmentTable() {
  const [departments, setDepartments] = useState([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const {loader, setLoader, isArchived, activeOrganization} = usePageUtils()
    const [tableLoading, setTableLoading] = useState(false)
      const [search, setSearch] = useState('');

  const getDepartments = useCallback(async () => {
    if (loader) return;
    setTableLoading(true);
    setLoader(true);
    setDepartments([]);
    try {
      let params = {};
      params.search = search;
      if (isArchived) {
        params.archived = true;
      }
      const {data} = await Api.Get(`/organization/${activeOrganization?.id}/department`, params);
      setDepartments(data);
    } catch (error) {
      console.error(error);
    } finally {
      setTableLoading(false)
      setLoader(false)
    }
  }, [activeOrganization, isArchived , search]);

  useEffect(() => {
    getDepartments();
  }, [getDepartments])

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = (refresh) => {
    setIsModalOpen(false);
    if (refresh) {
      setSelectedDepartment(undefined)
      getDepartments()
    }
  };

  const handleEdit = (department) => {
    showModal();
    setSelectedDepartment(department);
  };

  const handleDepartmentArchive = useCallback((department) => {
    setSelectedDepartment(department);
    setShowArchiveModal(true);
  }, []);

  const handleDeleteModalCancel = useCallback((refresh) => {
    setShowArchiveModal(false);
    setSelectedDepartment(undefined)
    if (refresh) {
      getDepartments()
    }

  }, [getDepartments]);

  const columns = [
    {
      title: "Department",
      dataIndex: "name",
      render: (text, record) => (
          <div className='flex gap-2 items-center mr-4'>
            <DepartmentIcon
                selected={false}
                value={record.color}
                size='small'
            />
            <p>{text}</p>
          </div>
      ),
    },
    {
      title: "Members",
      dataIndex: "members",
      render: (members) => (
          <>
            {members ? (
                <div className='avatar'>
                  <Avatar.Group
                      maxCount={2}
                      maxPopoverTrigger='hover'
                      size='default'
                      maxStyle={{
                        color: "#f56a00",
                        backgroundColor: "#fde3cf",
                        cursor: "pointer",
                      }}>
                    {members.map((item, index) => (
                        <Tooltip
                            title={item.name}
                            placement='top'>
                          <Avatar
                              key={item.avatar}
                              src={item?.avatar}
                          />
                        </Tooltip>
                    ))}
                  </Avatar.Group>
                </div>
            ) : (
                <p className='ml-6'>No member available</p>
            )}
          </>
      ),
    },
    {
      title: "Department Head",
      dataIndex: "department_head",
      render: (record) => (
          <>
            {record ? (
                <div className='flex gap-2 items-center'>
                  <img
                      src={record.avatar}
                      className='w-10 h-10 rounded-full object-cover object-top'
                      alt='head'
                  />
                  {/*<p className="line-clamp-1">{record.name}</p>*/}
                  <p className="w-60">{record.name}</p>
                </div>
            ) : (
                <p className='ml-6'>No head assigned</p>
            )}
          </>
      ),
    },
    {
      ...(['admin'].includes(activeOrganization?.role) && {
        title: "Action",
        key: "action",
        width: 150,
        render: (_, record) => (
            <div className='flex gap-3'>
              {!isArchived && (
                  <button
                      className='w-28 text-white rounded-md py-1 bg-[#73C255]'
                      onClick={() => handleEdit(record)}>
                    Edit
                  </button>
              )}
              <button
                  className={isArchived ? 'bg-green-600 text-white border-green-600 w-24 rounded-md py-1' : 'w-24 text-white rounded-md py-1 bg-[#EE3636]'}
                  onClick={() => handleDepartmentArchive(record)}>
                {isArchived ? 'Restore' : 'Archive'}
              </button>
            </div>
        ),
      }),
    }
  ];

  return (
      <div className='overflow-auto mt-1 p-2 bg-white rounded-md'>
         <div className="text-right">
        <Space className="my-2.5">
          <Search
            disabled={tableLoading}
            placeholder='Search'
            allowClear
            onSearch={(value) => {
              setSearch(value)
            }}
            size="large"
          />

        </Space>
      </div>
        <Table
            loading={loader}
            dataSource={departments}
            columns={columns}
            pagination={true}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Department Found"
                />
              ),
            }}
        />
        <DepartmentDeleteModal
            archived={isArchived}
            department={selectedDepartment}
            onClose={handleDeleteModalCancel}
            open={showArchiveModal}
        />
        <DepartmentModal
            onClose={handleCancel}
            open={isModalOpen}
            department={selectedDepartment}
        />
      </div>
  );
})

export default DepartmentTable
