import React, {useCallback, useEffect, useState} from 'react';
import MainLayout from '../../../layouts/MainLayout';
import {Button, Table as AntTable, Tag, Tooltip, Empty} from 'antd';
import {useNavigate} from 'react-router-dom';
import Filters from '../components/Filters';
import Authorized from '../../../components/Middleware/Authorized';
import {useSelector} from 'react-redux';
import Api from '../../../utils/Axios';

const ENABLED_FILTERS = {
  departments: true,
  users: true,
  members: true,
  single_picker: true
}

export default function InOutsReport() {

  const navigate = useNavigate();
  const [filters, setFilters] = useState(undefined)
  const [dataRecord , setDataRecord] = useState([]);

  return (
    <MainLayout>
      <Authorized permission={true}>
        <div className='md:p-5 py-2'>
          <div className='flex items-center gap-x-6'>
            <div className='flex items-center gap-4 mt-4 mb-7'>
              <Button className="w-10 h-10 flex justify-center items-center p-2" onClick={() => navigate(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-12 h-12">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"/>
                </svg>
              </Button>
              <h1 className='xl:text-2xl text-2xl lg:text-xl my-4 font-semibold font-poppins'>
                In & Outs
              </h1>
            </div>
            <div className='flex justify-end items-center flex-1 gap-2'>
              <div className="flex gap-2 xl:items-center main flex-col flex-1">
                <div className="flex gap-2 flex-col md:flex-row flex-wrap justify-end w-full">
                  <Filters
                    filterss={ENABLED_FILTERS}
                    onChange={(newFilters) => setFilters(newFilters)}
                    report='in-and-outs'
                    dataRecord = {dataRecord}
                  />
                </div>
              </div>
            </div>
          </div>
          <Table filters={filters} setDataRecord={setDataRecord}/>
        </div>
      </Authorized>
    </MainLayout>
  )
}


const Table = ({filters , setDataRecord}) => {

  const [data, setData] = useState([]);
  const [recordFilters, setRecordFilters] = useState(undefined)
  const [pagination, setPagination] = useState(undefined);
  const [loadingMore, setLoadingMore] = useState(false);
  const {activeOrganization} = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false);

  const getReport = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: {
          data: records,
          pagination: paginationData
        }
      } = await Api.Get(`organization/${activeOrganization.id}/report/in-and-outs`, recordFilters)
      setData(records);
      

      setPagination(paginationData)
    } catch (error) {
      console.error(error);
    }
    finally{
      setLoading(false);
    }
  }, [activeOrganization, recordFilters])

  const handleLoadMore = useCallback(async () => {
    try {
      setLoadingMore(true);
      const {data: {data: records, pagination: paginationData}} = await Api.Get(pagination?.links?.next, recordFilters)
      setData((prev) => [
        ...prev,
        ...records
      ]);
      setPagination(paginationData)

    } catch (error) {

    } finally {
      setLoadingMore(false)
    }
  }, [pagination, recordFilters])

  useEffect(() => {
    getReport()
  }, [getReport])

  useEffect(() => {
    if (!filters) return

    setRecordFilters(filters);
  }, [filters])

  useEffect(() => {
      setDataRecord(data)
     }, [data])

  const columns = [
    {
      title: 'Date',
      dataIndex: "date",
      key: "date",
    },
    {
      title: 'Name',
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Department",
      dataIndex: "department_names",
      align: "center",
      render: (departments) => {
        const firstTwoDepartments = departments.slice(0, 2);
        const restDepartments = departments.slice(2);

        return (
          <>
            {firstTwoDepartments.map((department, index) => (
              <Tag color='purple' className='font-poppins mt-1' key={index}>
                {department}
              </Tag>
            ))}
            {restDepartments.length > 0 && (
              <Tooltip title={restDepartments.map(department => department).join(', ')}>
                <Tag color='purple' className='font-poppins mt-1'>
                  +{restDepartments.length}
                </Tag>
              </Tooltip>
            )}
          </>
        )

      }
    },
    {
      title: 'In at',
      dataIndex: "in_at",
      key: "in_at",
    },
    {
      title: 'Out at',
      dataIndex: "out_at",
      key: "out_at",
      render: (outAt) => outAt ? outAt : <span className="text-gray-500 italic md:ml-4">-</span>
    },
    {
      title: 'Duration',
      dataIndex: "duration",
      key: "duration",
    }
  ];

  return (
    <div className='bg-white rounded-md p-2 mt-3'>
      <AntTable
        dataSource={data}
        columns={columns}
        className='m-2'
        pagination={false}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No In and Outs Data Found"
            />
          ),
        }}
      />
      {
        pagination?.links?.next && (
          <div className="load-more sticky flex justify-center top-0">
            <Button
              onClick={handleLoadMore}
              type="primary"
              className="font-poppins py-2 px-5 h-auto"
              loading={loadingMore}
            >Load More</Button>
          </div>
        )
      }
    </div>
  );
}
