import React, {useCallback, useEffect, useState} from "react";
import {Button, Table, Tag, Tooltip, Empty} from "antd";
import {
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import {BsPersonFillSlash, BsPersonFillCheck} from "react-icons/bs";
import {useSelector} from "react-redux";
import Api from '../../utils/Axios';
import DeleteModal from "../../components/Modals/DeleteModal";
import SuspendModal from "../../components/Modals/SuspendModal";
import UpdateModal from "../../components/Modals/UpdateModal";
import TeamMember from "../../components/TeamManagement/TeamMember";


export default function TeamTable({query = null, onCountChange}) {

  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const {user} = useSelector((state) => state.auth);
  const {activeOrganization} = useSelector((state) => state.auth);

  const getTeam = useCallback(async () => {
    try {
      setLoading(true);
      const {data} = await Api.Get(
          `organization/${activeOrganization?.id}/team/member`,
          {
            search: query,
          }
      );
      setRecords(data.data)
      setPagination(data.pagination);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [activeOrganization, query]);

  const handleDeleteModal = useCallback((record) => {
    setSelectedMember(record);
    setShowDeleteModal(true);
  }, []);

  const handleSuspendClose = (shouldRefresh) => {
    setSelectedMember(undefined);
    setShowSuspendModal(false);
    if (shouldRefresh) {
      getTeam();

    }
  };

  const handleDeleteClose = (shouldRefresh) => {
    setSelectedMember(undefined);
    setShowDeleteModal(false);
    if (shouldRefresh) {
      getTeam();
    }
  };

  const roleSorter = (a, b) => {
    // Custom sorting logic
    const rolesOrder = {
      admin: 1,
      "department_head": 2,
      user: 3,
    };

    const roleA = a.role;
    const roleB = b.role;

    if (rolesOrder[roleA] < rolesOrder[roleB]) {
      return 1;
    }
    if (rolesOrder[roleA] > rolesOrder[roleB]) {
      return -1;
    }
    return 0;
  };

  const handleEditClose = (shouldRefresh) => {
    setSelectedMember(undefined);
    setShowMemberModal(false);
    if (shouldRefresh) {
      getTeam();
    }
  };

  const handleLoadMore = useCallback(async () => {
    try {
      setLoading(true);
      const {data} = await Api.Get(pagination?.links?.next)
      setRecords((prev) => [
        ...prev,
        ...data.data
      ]);
      setPagination(data.pagination)
    } catch (error) {

    } finally {
      setLoading(false);
    }
  }, [pagination])

  const handleEntityEdit = (data) => {
    setSelectedMember(data);
    setShowMemberModal(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => <TeamMember invite={record}/>,
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (text) => (<p className="capitalize">{text.replace('_', ' ')}</p>),
      sorter: roleSorter,
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (department) => {
        if (Array.isArray(department)) {
          return department.map(item => (
              <Tag key={item.id}>{item.name}</Tag>
          ));
        } else if (typeof department === 'object') {
          return <Tag>{department.name}</Tag>;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Actions",
      render: (_, record) => record.is_editable && (
          <div className="flex gap-2">
            <Tooltip title="Edit" className="text-primary text-lg">
              <Button
                  type="ghost"
                  onClick={() => handleEntityEdit(record)}
                  className="p-1 flex justify-center items-center"
              >
                <EditOutlined/>
              </Button>
            </Tooltip>
            {
              user.id !== record.id && (
                  <Tooltip title={record.status.toLowerCase() === 'suspended' ? 'Resume' : 'Suspend'}
                           className="text-primary text-lg">
                    <Button
                        onClick={() => {
                          setSelectedMember(record)
                          setShowSuspendModal(true)
                        }}
                        type="ghost"
                        className="p-1 flex justify-center items-center"
                    >
                      {
                        record.status.toLowerCase() === 'active' ?
                            <BsPersonFillSlash className="text-red-500" size={20}/>
                            :
                            <BsPersonFillCheck className="text-green-500" size={20}/>
                      }
                    </Button>
                  </Tooltip>
              )
            }
            {
              (activeOrganization.role === 'admin' && !record?.undeletable) &&
              (
                  <Tooltip title="Delete" className="text-lg">
                    <Button
                        onClick={() => handleDeleteModal(record)}
                        type="ghost"
                        className="p-1 flex justify-center items-center text-red-500 "
                    >
                      <DeleteOutlined/>
                    </Button>
                  </Tooltip>
              )
            }
          </div>
      ),
    },
  ];

  useEffect(() => {
    getTeam()
  }, [getTeam])

  useEffect(() => {
    onCountChange(records.filter((record) => record.status.toLowerCase() === "active").length ?? 0)
  }, [records, onCountChange])

  return (
      <div className="overflow-auto bg-white rounded-md p-2 mt-3">
        <Table
            columns={columns}
            dataSource={records}
            bordered="true"
            pagination={false}
            loading={loading}
            locale={{
              emptyText: (
                  <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No team Found"
                  />
              ),
            }}
        />

        {
          pagination?.links?.next && (
              <div className="load-more sticky flex justify-center top-0 mt-5">
                <Button onClick={handleLoadMore} type="primary" className="font-poppins py-2 px-5 h-auto"
                        loading={false}>Load More</Button>
              </div>
          )
        }

        <SuspendModal
            onClose={handleSuspendClose}
            open={showSuspendModal}
            entity={selectedMember}
            suspended={selectedMember?.status?.toLowerCase() === 'suspended'}
        />

        <DeleteModal
            onClose={handleDeleteClose}
            open={showDeleteModal}
            entity={selectedMember}
            mode={'members'}
        />

        <UpdateModal
            onClose={handleEditClose}
            open={showMemberModal}
            member={selectedMember}
        />

      </div>
  );
};
