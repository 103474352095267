import React from 'react';
import { Alert, Flex, Spin } from 'antd';
const contentStyle = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
};
const content = <div style={contentStyle} />;
const App = () => (
    <div className="fullscreen-overlay">
        <Spin tip="" size="large">
            {content}
        </Spin>
    </div>
);
export default App;