import { Button, Modal, message as Notify } from 'antd'
import React, { useCallback, useState } from 'react'
import Api from '../../utils/Axios';
import { useSelector } from 'react-redux';

export default function MeetingsDeleteModal({ open, meeting, isArchived, onClose }) {

  const { activeOrganization } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const handleConfirm = useCallback(async() => {
    try {
      setLoading(true)
      const { message } = await Api.Get(`organization/${activeOrganization.id}/meeting/${meeting.id}/${isArchived ? 'restore' : 'archive'}`)
      Notify.success(message)
      onClose(true);
    } catch (error) {
      console.error(error)
      Notify.error('Something went wrong');
    } finally {
      setLoading(false)
    }
  }, [meeting, activeOrganization, onClose, isArchived])

  return (
    <div>
      <Modal
        open={open}
        onCancel={onClose}
        footer={false}
        centered
      >
        <div className='md:m-10 flex flex-col items-center text-center font-poppins'>
          <p className='font-slightly-bold text-2xl my-2'>Are you sure you want to {!isArchived ? 'Archive' : 'Unarchive'} this Meeting? </p>
          <div className='flex gap-5'>
            <Button className=' w-36 h-10 mt-5 rounded-sm' onClick={onClose}>Cancel</Button>
            <Button type="primary" className='bg-primary h-10 w-36 mt-5 rounded-sm' onClick={handleConfirm} loading={loading}>Confirm</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
