import { createSlice } from "@reduxjs/toolkit";
import { getTrackerAppUrl } from "./globalAction";

const initialState = {
  windows_build: localStorage.getItem('window_build') ?? '',
  // mac_build: localStorage.getItem('mac_build') ?? ''
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setTrackerAppUrl: (state, action) => {
      const { assets } = action.payload.data;
      const window_build = assets.find(item => item.content_type === 'application/x-msdownload')?.browser_download_url;
      const mac_build = assets.find(item => item.content_type === 'application/octet-stream')?.browser_download_url;
      state.windows_build = window_build;
      state.mac_build = mac_build;
      localStorage.setItem('windows_build', window_build)
      localStorage.setItem('mac_build', mac_build)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTrackerAppUrl.fulfilled, (state, action) => {
      globalSlice.caseReducers.setTrackerAppUrl(state, { payload: action.payload });
    });
  },
});

export default globalSlice.reducer;
