import React, { useCallback, useEffect, useState } from 'react'
import useScreenSize from '../../../hooks/useScreenSize';
import { Button, Drawer, Table, Tag, Tooltip, message as Notify, Empty } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import Api from '../../../utils/Axios';
import clsx from 'clsx';
import { LuDownload } from 'react-icons/lu';
import { getFormattedTimestamp } from '../../../utils/helpers';

export default function AttendanceDrawer({ record, dates, onClose, }) {

  const [records, setRecords] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const { isMobile } = useScreenSize();
  const { activeOrganization } = useSelector((state) => state.auth);

  const getAttendance = useCallback(async () => {

    if (!record || record === undefined) return false;

    try {

      const { data } = await Api.Get(`organization/${activeOrganization.id}/report/user-attendance/${record.id}`, {
        start_date: dates[0],
        end_date: dates[1]
      })
      setRecords(data)

    } catch (error) {
      console.error(error)
    }
  }, [activeOrganization, dates, record])




  useEffect(() => {
    getAttendance()
  }, [getAttendance])


  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Department',
      dataIndex: 'department_names',
      key: 'department_names',
      render: (data) => {
        const firstTwoDepartments = data.slice(0, 2);
        const restDepartments = data.slice(2);
        return (
          <>
            {firstTwoDepartments.map((department, index) => (
              <Tag color='purple' className='font-poppins mt-1' key={index}>
                {department}
              </Tag>
            ))}
            {restDepartments.length > 0 && (
              <Tooltip title={restDepartments.map(department => department).join(', ')}>
                <Tag color='purple' className='font-poppins mt-1'>
                  +{restDepartments.length}
                </Tag>
              </Tooltip>
            )}
          </>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (data) => (
        <p className={clsx({
          'capitalize': true,
          'text-red-500': data === 'absent',
          'text-green-500': data === 'present'
        })}>
          {data}
        </p>
      )
    },
    {
      title: 'Clock In',
      dataIndex: 'clock_in',
      key: 'clock_in',
      render: (data) => {
        return data === '--' ? '--' : getFormattedTimestamp(data, activeOrganization.timezone, 'hh:mm a')
      }
    },
    {
      title: 'Clock Out',
      dataIndex: 'clock_out',
      key: 'clock_out',
      render: (data) => {
        return data === '--' ? '--' : getFormattedTimestamp(data, activeOrganization.timezone, 'hh:mm a')
      }
    }
  ]

  const downloadCSV = useCallback(async () => {
  
    if(records.length < 1){
      Notify.error("No data available to download");
      return;
    }

    try {
      setDownloading(true);
      const response = await Api.Get(`organization/${activeOrganization.id}/report/user-attendance/${record?.id}/download`, {
        start_date: dates[0],
        end_date: dates[1]
      }, {
        responseType: 'blob'
      })
      if (response) {
        const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        const filename = `${record?.name}-attendance-report.csv`;
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error(error)
    } finally {
      setDownloading(false);
    }
  }, [activeOrganization, record, records])

  return (
    <Drawer
      placement='right'
      closable={false}
      onClose={onClose}
      open={!!record}
      width={isMobile ? "100%" : "70%"}>
      <div>
        <div className='flex justify-between items-center font-poppins sticky top-[-16px] bg-white z-10 mt-5'>
          <div className='flex gap-x-2'>
            <button onClick={onClose}>
              <LeftOutlined />
            </button>
            <h1 className='text-[#263238] text-xl'> {record?.name }'s Attendance Insights</h1>
          </div>
          <Button className='gap-2 items-center flex bg-white' onClick={downloadCSV} loading={downloading}>
            <LuDownload size={20} className='text-primary'/>
            <span className='text-[#9DA5B5]'>CSV</span>
          </Button>
        </div>
        <div className="mt-5">
          <Table
            dataSource={records}
            columns={columns}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Attendance Insights Found"
                />
              ),
            }}
          />
        </div>
      </div>
    </Drawer>
  )
}
