import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Typography,
  message as Notify,
  Radio,
  Row,
  Col,
  message
} from 'antd';
import {useForm, useWatch} from 'antd/es/form/Form';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import Api from '../../utils/Axios';
import dayjs from 'dayjs';

export default function MeetingModal({open, onClose, meeting = undefined}) {

  const {activeOrganization} = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [meetingForm] = useForm();
  const meetingType = useWatch('type', meetingForm)
  const meetingDate = useWatch('date', meetingForm)

  const isValidURL = (value) => {

    if (!value || value.trim() === "") {
      return "Please enter the meeting URL";
    }

    if (/\s/.test(value)) {
      return "Meeting URL should not contain spaces only.";
    }

    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/.*)?$/;

    if (!/^https?:\/\//.test(value)) {
      return "Meeting URL should start with http:// or https://";
    }

    if (/^htp:\/\//.test(value)) {
      return "Protocol is incorrect. It should be http:// or https://";
    }

    if (/http:\/\/http:\/\//.test(value)) {
      return "Double protocol detected (http://http://). Please remove the extra protocol.";
    }

    if (!/https?:\/\/[^\s]+\.[^\s]+/.test(value)) {
      return "Missing domain. Please provide a valid domain name.";
    }

    if (/\.\./.test(value)) {
      return "Invalid domain name. Double dots in the domain are not allowed.";
    }

    if (/[^a-zA-Z0-9.-]/.test(value.split('://')[1].split('/')[0])) {
      return "Special characters are not allowed in the domain name.";
    }

    if (/^\d+\.\d+\.\d+\.\d+$/.test(value)) {
      return "IP addresses must include a protocol (http:// or https://).";
    }

    if (/:[0-9]{5,}$/.test(value)) {
      return "Port number is invalid. It should be between 1 and 65535.";
    }

    const invalidTLDs = ['xyzzy', 'invalid']; // Add more invalid TLDs if necessary
    const tld = value.split('.').pop();
    if (invalidTLDs.includes(tld)) {
      return `Invalid TLD. The .${tld} TLD is not allowed.`;
    }

    if (value.length < 10) {
      return "URL is too short and might be broken.";
    }

    if (!urlPattern.test(value)) {
      return "Please enter a valid URL.";
    }

    return null;
  };

  const getOrganizationUsers = useCallback(async () => {
    try {
      const {data} = await Api.Get(`/organization/${activeOrganization?.id}/users`);
      setUsers(() => {
        return data.map(item => ({value: item.id, label: item.name}))
      });
    } catch (error) {

    }
  }, [activeOrganization]);

  const handleModalClose = useCallback((refresh = false) => {
    meetingForm.resetFields();
    onClose(refresh)
  }, [onClose, meetingForm])

  const handleFormSubmit = useCallback(async (values) => {
    try {
      setLoading(true);

      values = {
        ...values,
        date: dayjs(values.date).format('YYYY-MM-DD'),
        start_time: values.startTime.replace(' PM', '').replace(' AM', ''),
        end_time: values.endTime.replace(' PM', '').replace(' AM', ''),
      }

      delete values['time'];

      let call;

      if (meeting) {
        call = () => Api.Put(`/organization/${activeOrganization?.id}/meeting/${meeting?.id}`, values);
      } else {
        call = () => Api.Post(`/organization/${activeOrganization?.id}/meeting`, values);
      }

      const {message} = await call();

      meetingForm.resetFields();
      Notify.success(message);
      handleModalClose(true)

    } catch (error) {
      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        const validationErrors = Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key],
        }));
        meetingForm.setFields(validationErrors);
        Notify.error(error.response.data.message);
      } else {
        console.error(error);
        Notify.error('Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  }, [activeOrganization, meetingForm, meeting, handleModalClose]);

  useEffect(() => {
    if (meeting) {
      const ref = {
        ...meeting,
        date: dayjs(meeting.start_time),
        time: [
          dayjs(meeting.start_time).format('HH:mm A'),
          dayjs(meeting.end_time).format('HH:mm A'),
        ],
        attendees: meeting.attendees?.map(member => member.id) ?? []
      }
      meetingForm.setFieldsValue(ref);
    }
  }, [meeting, meetingForm])

  useEffect(() => {
    getOrganizationUsers()
  }, [getOrganizationUsers])

  return (
    <div>
      <Modal
        open={open}
        onCancel={() => handleModalClose()}
        className='mt-0'
        footer={false}
        width={700}
      >
        <div className='font-poppins'>
          <h1 className='font-slightly-bold text-lg'>{meeting ? "Edit Meeting" : "Schedule new meeting"}</h1>
          <Form layout='vertical' className='mt-4 meeting' onFinish={handleFormSubmit} form={meetingForm}
                disabled={loading} initialValues={{type: 'online'}}>
            <div>
              <Typography.Paragraph
                className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Title <span
                className="text-red-500">*</span></Typography.Paragraph>
              <Form.Item name="title" rules={[
                {required: true, message: 'Please enter a meeting title'},
                {
                  pattern: /^[A-Za-z0-9]+(?:'[A-Za-z0-9]+)*(?: [A-Za-z0-9]+(?:'[A-Za-z0-9]+)*)*$/,
                  message: 'Meeting title should only contain letters, numbers, and spaces',
                },
                {
                  validator: (_, value) => {
                    if (value && value.trim() === '') {
                      return Promise.reject('Please enter a meeting title');
                    }
                    return Promise.resolve();
                  },
                },
              ]}>
                <Input className='w-full px-2 py-2 border rounded-md' placeholder='Enter your meeting title'/>
              </Form.Item>
            </div>

            <div className='flex gap-3'>
              <div className='w-1/2'>
                <Typography.Paragraph
                  className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Date <span
                  className="text-red-500">*</span></Typography.Paragraph>
                <Form.Item name="date" rules={[{required: true, message: 'Please select a valid date'}]}>
                  <DatePicker
                    className='w-full px-2 py-2 border rounded-md'
                    disabledDate={(current) => current && current.startOf('day').isBefore(dayjs().startOf('day'))}
                    inputReadOnly={true}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </div>
              <div className='w-1/2'>
                <Form.Item format='HH:mm' name="time">
                  <CustomTimePicker date={meetingDate} form={meetingForm}/>
                </Form.Item>
              </div>
            </div>
            <div>
              <Typography.Paragraph
                className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Members <span
                className="text-red-500">*</span></Typography.Paragraph>
              <Form.Item className='multi' name="attendees"
                         rules={[{required: true, message: 'Please select at least one member.'}]}>
                <Select
                  mode="multiple"
                  // tagRender={<ProjectTags />}
                  style={{width: '100%'}}
                  placeholder="Select members"
                  options={users}
                />
              </Form.Item>
            </div>
            <div>
              <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Meeting
                Type</Typography.Paragraph>
              <Form.Item name='type'>
                <Radio.Group defaultValue='online'>
                  <Radio value='online'>Online</Radio>
                  <Radio value='in-person'>In Person</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            {
              meetingType === 'online' && (
                <div>
                  <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Meeting
                    URL <span
                      className="text-red-500">*</span></Typography.Paragraph>
                  <Form.Item name="link"
                             rules={[
                               {
                                 validator: async (_, value) => {
                                   const errorMessage = isValidURL(value);
                                   if (errorMessage) {
                                     return Promise.reject(errorMessage);
                                   }
                                   return Promise.resolve();
                                 }
                               }]}
                  >
                    <Input className='w-full px-2 py-2 border rounded-md' placeholder='Enter meeting url'/>
                  </Form.Item>
                </div>
              )
            }

            {
              meetingType === 'in-person' && (
                <div>
                  <Typography.Paragraph
                    className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Location <span
                    className="text-red-500">*</span></Typography.Paragraph>
                  <Form.Item name="location"
                             rules={[
                               {required: true, message: 'Please enter a meeting location'},
                               {
                                 pattern: /^[a-zA-Z0-9\s]*$/,
                                 message: 'Meeting location should only contain letters, numbers, and spaces',
                               },
                               {
                                 validator: (_, value) => {
                                   if (value && value.trim() === '') {
                                     return Promise.reject('Please enter a meeting location');
                                   }
                                   return Promise.resolve();
                                 },
                               },
                             ]}
                  >
                    <Input className='w-full px-2 py-2 border rounded-md' placeholder='Enter meeting location'/>
                  </Form.Item>
                </div>
              )
            }

            <div className='flex gap-5'>
              <Button type="primary" htmlType='submit' className='w-1/2 bg-primary h-10 mt-5 rounded-md'
                      loading={loading}>{meeting ? 'Update' : 'Create'}</Button>
              <Button className=' w-1/2 h-10 mt-5 rounded-md' onClick={onClose}>Cancel</Button>
            </div>
          </Form>
        </div>

      </Modal>
    </div>
  )
}

const CustomTimePicker = ({value, onChange, date, form}) => {

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const isTimePassed = useCallback((time) => {
    if (!date) return true;

    const selectedDate = date
    const slotTime = dayjs(`${selectedDate.format('MM/DD/YYYY')} ${time}`, 'MM/DD/YYYY HH:mm');
    const currentTime = dayjs();

    if (selectedDate.isAfter(currentTime, 'day')) {
      return false;
    }

    if (selectedDate.isSame(currentTime, 'day') && currentTime.isAfter(slotTime)) {
      return true;
    }

    if (selectedDate.isBefore(currentTime, 'day')) {
      return true;
    }

    return false;
  }, [date]);

  useEffect(() => {
    const sTime = form.getFieldValue('startTime'),
      eTime = form.getFieldValue('endTime');
    if (date && (sTime == null && eTime == null)) {
      form.setFieldsValue({
        startTime: null,
        endTime: null,
      });
      form.setFields([
        {
          name: 'startTime',
          errors: ['Please select the start time'],
        },
        {
          name: 'endTime',
          errors: ['Please select the end time'],
        },
      ]);
    }
    if (date && (isTimePassed(sTime))) {
      form.setFieldsValue({
        startTime: null,
      });
      form.setFields([
        {
          name: 'startTime',
          errors: ['Please select the start time'],
        },
      ]);
    }

    if (date && (isTimePassed(eTime))) {
      form.setFieldsValue({
        endTime: null,
      });
      form.setFields([
        {
          name: 'endTime',
          errors: ['Please select the end time'],
        },
      ]);
    }
  }, [date, form]);

  const timeSlots = useMemo(() => {
    const slots = [];
    let start = dayjs().startOf('day');

    while (start.isBefore(dayjs().endOf('day'))) {
      slots.push({
        label: start.format('hh:mm A'),
        value: start.format('HH:mm A')
      });

      start = start.add(15, 'minute');
    }

    slots.push({
      label: '11:59 AM',
      value: '23:59 PM'
    });

    return slots;
  }, []);



  useEffect(() => {
    if (startTime && endTime) {
      if (startTime === endTime) {
        message.error('Start & End time cannot be same');
      }

      if (endTime < startTime) {
       message.error('End time cannot be earlier than start time');
      }
      onChange([startTime, endTime]);
    }
    // eslint-disable-next-line
  }, [startTime, endTime])

  useEffect(() => {
    if (value && Array.isArray(value)) {
      setStartTime(value[0])
      setEndTime(value[1])
    }
  }, [value])

  return (
    <Row gutter={12}>
      <Col span={12}>
        <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>Start
          Time <span
            className="text-red-500">*</span></Typography.Paragraph>
 <Form.Item
  name="startTime"
  rules={[{ required: true, message: 'Please select the start time' }]}
>
  <Select
    className="h-10"
    placeholder="Select start time"
    value={startTime}
    onChange={value => setStartTime(value)}
  >
    {timeSlots.slice(0, timeSlots.length - 1).map(time => (
      <Select.Option
        key={time.value}
        value={time.value}
        disabled={isTimePassed(time.value)}
      >
        {time.label}
      </Select.Option>
    ))}
  </Select>
</Form.Item>


      </Col>
      <Col span={12}>
        <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>End Time <span
          className="text-red-500">*</span></Typography.Paragraph>
 <Form.Item
  name="endTime"
  rules={[{ required: true, message: 'Please select the end time' }]}
>
  <Select
    className="h-10"
    placeholder="Select end time"
    value={endTime}
    onChange={value => setEndTime(value)}
  >
    {timeSlots.map((time, index) => (
      <Select.Option
        key={time.value}
        value={time.value}
        disabled={
          // if last slot, don't disable; otherwise use the condition
          index === timeSlots.length - 1 
            ? false 
            : (isTimePassed(time.value) || time.value <= startTime)
        }
      >
        {time.label}
      </Select.Option>
    ))}
  </Select>
</Form.Item>

      </Col>
    </Row>
  )
}
