import { Button, Tag, Tooltip, Table as AntTable, Empty } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../../layouts/MainLayout';
import Api from '../../../utils/Axios';
import Filters from '../components/Filters';
import Authorized from '../../../components/Middleware/Authorized';

const ENABLED_FILTERS = {
  departments: true,
  users: true,
  single_picker: true
}

export default function OvertimeReport() {

  const navigate = useNavigate();
  const [filters, setFilters] = useState(undefined)
  const [dataRecord , setDataRecord] = useState([]);


  return (
    <MainLayout>
      <Authorized permission='overtime-analysis'>
        <div className='md:p-5 py-2'>
          <div className='flex items-center gap-x-6'>
            <div className='flex items-center gap-4 mt-4 mb-7'>
              <Button className="w-10 h-10 flex justify-center items-center p-2" onClick={() => navigate(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
              </Button>
              <h1 className='xl:text-2xl text-2xl lg:text-xl my-4 font-semibold font-poppins'>
                Overtime Analysis
              </h1>
            </div>
            <div className='flex justify-end items-center flex-1 gap-2'>
              <div className="flex gap-2 xl:items-center main flex-col flex-1">
                <div className="flex gap-2 flex-col md:flex-row flex-wrap justify-end w-full">
                  <Filters
                    filterss={ENABLED_FILTERS}
                    onChange={(newFilters) => setFilters(newFilters)}
                    report='overtime-analysis'
                    dataRecord = {dataRecord}
                  />
                </div>
              </div>
            </div>
          </div>
          <Table filters={filters} setDataRecord={setDataRecord} />
        </div>
      </Authorized>
    </MainLayout>
  )
}


const Table = ({ filters , setDataRecord }) => {

  const [data, setData] = useState([]);
  const [recordFilters, setRecordFilters] = useState(undefined)
  const [totalHours, setTotalHours] = useState(undefined);
  const { activeOrganization } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false);

  const getReport = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await Api.Get(`organization/${activeOrganization.id}/report/overtime-analysis`, recordFilters)
      setData(data.data);
      setTotalHours(data.total_hours)
    } catch (error) {
      console.error(error);
    }
    finally{
      setLoading(false);
    }
  }, [activeOrganization, recordFilters])

  useEffect(() => {
    getReport()
  }, [getReport])

  useEffect(() => {
    if (!filters) return

    setRecordFilters(filters);
  }, [filters])

    useEffect(() => {
     setDataRecord(data)
    }, [data])

  const columns = [
    {
      title: 'Name',
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Department",
      dataIndex: "department_names",
      align: "center",
      render: (departments) => {
        const firstTwoDepartments = departments.slice(0, 2);
        const restDepartments = departments.slice(2);

        return (
          <>
            {firstTwoDepartments.map((department, index) => (
              <Tag color='purple' className='font-poppins mt-1' key={index}>
                {department}
              </Tag>
            ))}
            {restDepartments.length > 0 && (
              <Tooltip title={restDepartments.map(department => department).join(', ')}>
                <Tag color='purple' className='font-poppins mt-1'>
                  +{restDepartments.length}
                </Tag>
              </Tooltip>
            )}
          </>
        )

      }
    },
    {
      title: 'Regular hours',
      dataIndex: "expected_hours",
      key: "expected_hours",
    },
    {
      title: 'Overtime hours',
      dataIndex: "overtime",
      key: "overtime",
    }
  ];

  return (
    <div className='bg-white rounded-md p-2 mt-3'>
      <AntTable
        dataSource={data}
        columns={columns}
        className='m-2'
        pagination={false}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Overtime Analysis Found"
            />
          ),
        }}
      />
      <div className='sticky bottom-3 mt-4 flex px-7 bg-[#F1F4F9] justify-between p-5 rounded-md font-poppins text-[#263238] font-semibold'>
        <p className=''>Total</p>
        <p>{totalHours}</p>
      </div>
    </div>
  );
}
