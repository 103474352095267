import {useCallback, useEffect, useMemo, useState} from "react";
import MainLayout from "../../layouts/MainLayout";
import {Button, Table, Empty} from "antd";
import TimeSheetDrawer from "../../components/Timesheet/TimeSheetDrawer";
import Filters from "./components/Filters";
import {useSelector} from "react-redux";
import Api from "../../utils/Axios";
import dayjs from "dayjs";
import {secondsToHHMM} from "../../utils/helpers";
import './styles.css';
import {useNavigate} from "react-router-dom";


export default function Timesheet() {

  const {activeOrganization} = useSelector((state) => state.auth);
  const [departments, setDepartments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timesheet, setTimesheet] = useState([]);
  const [worklog, setWorklog] = useState(undefined);
  const [filters, setFilters] = useState(undefined);
  const [timeLogLoading, setTimeLogLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();

  const getMetaInfo = useCallback(async () => {
    try {
      const {
        data: {
          users,
          projects,
          departments
        }
      } = await Api.Get(`organization/${activeOrganization?.id}/timesheet/meta`)

      setUsers(users.map(item => ({value: item.id, label: item.name})));
      setProjects(projects.map(item => ({value: item.id, label: item.name})));
      setDepartments(departments.map(item => ({value: item.id, label: item.name})));

    } catch (error) {
      console.error(error)
    }
  }, [activeOrganization])

  const getTimesheet = useCallback(async () => {
    try {
      setLoading(true);
      const {data} = await Api.Post(`organization/${activeOrganization.id}/timesheet`, {
        filters
      })
      setTimesheet(data);
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
    }
  }, [activeOrganization, filters])

  const toggleScreenshotDrawer = useCallback(async (index, project_id, total) => {
    if (!filters.dates) return
    try {
      setTimeLogLoading(true);
      setDrawerVisible(true);
      const startDate = filters?.dates?.[0] ?? new Date();
      const date = dayjs(startDate).weekday(index + 1);
      const userIds = filters?.users ?? users.map(user => user.value);
      const depIds = filters?.departments ?? departments.map(department => department.value);

      const {data} = await Api.Post(`organization/${activeOrganization.id}/worklog`, {
        date,
        project_id,
        users: userIds,
        departments: depIds
      })
      setWorklog({
        ...data,
        total_hours: total,
        user_ids: filters?.users ?? [],
        department_ids: filters?.departments ?? [],
      });
    } catch (error) {
      console.error(error)
    } finally {
      setTimeLogLoading(false)
    }

  }, [activeOrganization, filters, departments, users]);

  const onCloseDrawer = () => {
    setWorklog(undefined)
    setDrawerVisible(false);
  };

  const getColumns = useCallback(() => {
    const startDate = filters?.dates?.[0] ?? new Date();
    const columns = [
      {
        title: "Projects",
        dataIndex: "project",
        key: "project",
      },
    ]
    for (let i = 0; i <= 6; i++) {
      columns.push({
        title: () => (
          <>
            <p className="text-center">
              <span className="block">{dayjs(startDate).startOf('week').add(i, 'd').format('ddd')}</span>
              <span>{dayjs(startDate).startOf('week').add(i, 'd').format('DD-MM-YYYY')}</span>
            </p>
          </>
        ),
        dataIndex: `day_${i}`,
        render: (value, record) => (
          // eslint-disable-next-line
          <a href="javascript:void(0)" onClick={() => toggleScreenshotDrawer(i, record.project_id, value)}
             className={`${value > 0 && 'underline'}`}>
            {value > 0 ? secondsToHHMM(value) : '-'}
          </a>
        ),
        align: 'center'
      })
    }

    columns.push({
      title: 'Active Time',
      dataIndex: 'total',
      render: (value) => {
        return secondsToHHMM(value)
      }
    })

    return columns;
  }, [filters?.dates, toggleScreenshotDrawer]);

  const getTotalHours = useMemo(() => {
    return secondsToHHMM(Object.keys(timesheet).reduce((sum, key) => sum + parseInt(timesheet[key].total), 0));
  }, [timesheet]);

  useEffect(() => {
    getMetaInfo()
  }, [getMetaInfo])

  useEffect(() => {
    getTimesheet()
  }, [getTimesheet])

  return (
    <MainLayout>
      <div className='py-8 md:p-5 font-poppins'>
        <div className='flex flex-col xl:flex-row justify-between xl:items-center gap-x-10'>
          <div className="flex items-center gap-x-2 mt-4 mb-7">
            <Button className="w-10 h-10 flex justify-center items-center p-2" onClick={() => navigate(-1)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                   stroke="currentColor" className="w-12 h-12">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"/>
              </svg>
            </Button>
            <h1 className="text-2xl font-semibold">Timesheets Overview</h1>
          </div>
          <Filters
            data={{users, projects, departments}}
            onChange={(filters) => setFilters(filters)}
          />
        </div>
        <div className='bg-white rounded-md p-2'>
          <div className='overflow-auto'>
            <Table
              dataSource={timesheet}
              columns={getColumns()}
              pagination={false}
              loading={loading}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Timesheet Found"
                  />
                ),
              }}
            />
          </div>

          <div
            className='mt-4 flex px-7 bg-[#F1F4F9] justify-between p-5 rounded-md font-poppins text-[#263238] font-semibold'>
            <p className=''>Total</p>
            <p>{getTotalHours}</p>
          </div>
          <TimeSheetDrawer
            onClose={onCloseDrawer}
            open={drawerVisible}
            loading={timeLogLoading}
            worklog={worklog}
          />
        </div>
      </div>
    </MainLayout>
  );
}
